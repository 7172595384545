export type DepartmentCode = "08000" | "10000" | "51000" | "52000" | "54000" | "55000" | "57000" | "67000" | "68000" | "88000"

export interface Department {
  code: DepartmentCode
  name: string
  sector: "Alsace" | "Champagne-Ardenne" | "Lorraine"
}

export interface Row {
  StructureId: number
  StructureCode: string
  StructureNom: string
  StructureNomCourt: string
  StructureCodeDepartement: DepartmentCode
  AdresseWeb: string | null
}

export const departmentsCodes: DepartmentCode[] = ["08000", "10000", "51000", "52000", "67000", "68000", "54000", "55000", "57000", "88000"]

export const departments: { [key: string]: Department } = {
  "08000": {
    code: "08000",
    name: "Ardennes",
    sector: "Champagne-Ardenne",
  },
  "10000": {
    code: "10000",
    name: "Aube",
    sector: "Champagne-Ardenne",
  },
  "51000": {
    code: "51000",
    name: "Marne",
    sector: "Champagne-Ardenne",
  },
  "52000": {
    code: "52000",
    name: "Haute-Marne",
    sector: "Champagne-Ardenne",
  },
  "54000": {
    code: "54000",
    name: "Meurthe-et-Moselle",
    sector: "Lorraine",
  },
  "55000": {
    code: "55000",
    name: "Meuse",
    sector: "Lorraine",
  },
  "57000": {
    code: "57000",
    name: "Moselle",
    sector: "Lorraine",
  },
  "67000": {
    code: "67000",
    name: "Bas-Rhin",
    sector: "Alsace",
  },
  "68000": {
    code: "68000",
    name: "Haut-Rhin",
    sector: "Alsace",
  },
  "88000": {
    code: "88000",
    name: "Vosges",
    sector: "Lorraine",
  },
}

// SELECT
//   DISTINCT Structures.StructureId,
//   Structures.StructureCode,
//   Structures.StructureNom,
//   Structures.StructureNomCourt,
//   Structures.StructureCodeDepartement,
//   Adresses.AdresseWeb
// FROM Structures
// LEFT JOIN Adresses
// ON Adresses.StructureId = Structures.StructureId
// LEFT JOIN Affiliations
// ON Affiliations.StructureId = Structures.StructureId
//   AND (Affiliations.SaisonAnnee = 2021 OR Affiliations.SaisonAnnee = 2022)
//   AND Affiliations.EcrId != 0
// WHERE Structures.StructureEtat = 'A'
//   AND Structures.StructureCodeRegion = 'CR06'
//   AND Structures.StructureType = 'CLU'
//   AND Affiliations.AffilEtat = 'P'
// ORDER BY Structures.StructureCodeDepartement;

export const clubs: Row[] = [
  {
    StructureId: 1823,
    StructureCode: "0608003",
    StructureNom: "COMPAGNIE CHARLES DE GONZAGUE",
    StructureNomCourt: "CHARLEVILLE MEZIERES",
    StructureCodeDepartement: "08000",
    AdresseWeb: "http://archersdegonzague.fr"
  },
  {
    StructureId: 1827,
    StructureCode: "0608006",
    StructureNom: "LES ARCHERS MOUZONNAIS",
    StructureNomCourt: "MOUZON",
    StructureCodeDepartement: "08000",
    AdresseWeb: ""
  },
  {
    StructureId: 1821,
    StructureCode: "0608005",
    StructureNom: "RETHEL SPORTIF TIR A L'ARC",
    StructureNomCourt: "RETHEL",
    StructureCodeDepartement: "08000",
    AdresseWeb: ""
  },
  {
    StructureId: 1820,
    StructureCode: "0608002",
    StructureNom: "LA FLECHE SEDANAISE",
    StructureNomCourt: "SEDAN",
    StructureCodeDepartement: "08000",
    AdresseWeb: "http://www.laflechesedanaise.fr"
  },
  {
    StructureId: 1822,
    StructureCode: "0608001",
    StructureNom: "TIR A L'ARC DEUX VIREUX",
    StructureNomCourt: "VIREUX-WALLERAND",
    StructureCodeDepartement: "08000",
    AdresseWeb: ""
  },
  {
    StructureId: 1824,
    StructureCode: "0608007",
    StructureNom: "LES ARCHERS VRIGNOIS",
    StructureNomCourt: "VRIGNE AUX BOIS",
    StructureCodeDepartement: "08000",
    AdresseWeb: "http://lesarchersvrignois.jimdo.com"
  },
  {
    StructureId: 1844,
    StructureCode: "0610018",
    StructureNom: "LES ARCHERS OTHEENS",
    StructureNomCourt: "AIX EN OTHE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://archersotheens.jimdofree.com/"
  },
  {
    StructureId: 1837,
    StructureCode: "0610019",
    StructureNom: "LES ARCHERS DES PRES DORES",
    StructureNomCourt: "ARCIS SUR AUBE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://archers-de-pres-dores.clubeo.com/"
  },
  {
    StructureId: 1833,
    StructureCode: "0610017",
    StructureNom: "LES ARCHERS DU PEAGE",
    StructureNomCourt: "AUXON",
    StructureCodeDepartement: "10000",
    AdresseWeb: "http://club.quomodo.com/lesarchersdupeage"
  },
  {
    StructureId: 1829,
    StructureCode: "0610015",
    StructureNom: "FOYER DES JEUNES JEAN VILAR",
    StructureNomCourt: "BAR SUR SEINE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "http://archers-bar-sur-seine.clubeo.com"
  },
  {
    StructureId: 1835,
    StructureCode: "0610028",
    StructureNom: "LES ARCHERS BRIENNOIS",
    StructureNomCourt: "BRIENNE LE CHATEAU",
    StructureCodeDepartement: "10000",
    AdresseWeb: ""
  },
  {
    StructureId: 1838,
    StructureCode: "0610025",
    StructureNom: "CIE D'ARC DU CHAOURCOIS",
    StructureNomCourt: "CHAOURCE",
    StructureCodeDepartement: "10000",
    AdresseWeb: ""
  },
  {
    StructureId: 1845,
    StructureCode: "0610024",
    StructureNom: "LES ARCHERS DE L'ETOILE",
    StructureNomCourt: "MAIZIERES GDE PAROISSE",
    StructureCodeDepartement: "10000",
    AdresseWeb: null
  },
  {
    StructureId: 1834,
    StructureCode: "0610023",
    StructureNom: "ROMILLY SPORTS - 10",
    StructureNomCourt: "ROMILLY SUR SEINE",
    StructureCodeDepartement: "10000",
    AdresseWeb: ""
  },
  {
    StructureId: 1828,
    StructureCode: "0610009",
    StructureNom: "LES ARCHERS TROYENS",
    StructureNomCourt: "TROYES",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://lesarcherstroyens.sportsregions.fr/"
  },
  {
    StructureId: 1840,
    StructureCode: "0610016",
    StructureNom: "ARCHERS DU TEMPLE",
    StructureNomCourt: "VAUCHONVILLIERS",
    StructureCodeDepartement: "10000",
    AdresseWeb: "http://club.quomodo.com/lesarchersdutemple/"
  },
  {
    StructureId: 1832,
    StructureCode: "0610011",
    StructureNom: "ARCHERS VAL DE BARSE",
    StructureNomCourt: "VENDEUVRE SUR BARSE",
    StructureCodeDepartement: "10000",
    AdresseWeb: "https://www.archers-val-de-barse.fr/"
  },
  {
    StructureId: 1841,
    StructureCode: "0610022",
    StructureNom: "LES ARCHERS DE ST BERNARD",
    StructureNomCourt: "VILLE SOUS LA FERTE",
    StructureCodeDepartement: "10000",
    AdresseWeb: null
  },
  {
    StructureId: 1870,
    StructureCode: "0651035",
    StructureNom: "SECTION TIR A L'ARC FJEP",
    StructureNomCourt: "BAZANCOURT",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.fjepbazancourt.wordpress.com"
  },
  {
    StructureId: 2655,
    StructureCode: "0651213",
    StructureNom: "BEZANNES TIR A L'ARC",
    StructureNomCourt: "BEZANNES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "https://arcbezannes.onlc.fr/"
  },
  {
    StructureId: 1863,
    StructureCode: "0651036",
    StructureNom: "LES ARCHERS CHALONNAIS",
    StructureNomCourt: "CHALONS EN CHAMPAGNE",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.lesarcherschalonnais.com"
  },
  {
    StructureId: 1851,
    StructureCode: "0651051",
    StructureNom: "1ERE COMPAGNIE D ARC DE CHALONS EN CHAMP",
    StructureNomCourt: "CHALONS EN CHAMPAGNE 1ER",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.arc-chalons.fr/"
  },
  {
    StructureId: 1859,
    StructureCode: "0651033",
    StructureNom: "MJEP CORMONTREUIL ARC",
    StructureNomCourt: "CORMONTREUIL",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://arccormontreuil.onlc.fr/"
  },
  {
    StructureId: 1855,
    StructureCode: "0651030",
    StructureNom: "CLUB EPERNAY DE TIR A L'ARC",
    StructureNomCourt: "EPERNAY",
    StructureCodeDepartement: "51000",
    AdresseWeb: "https://ceptiralarcepernay.sportsregions.fr/"
  },
  {
    StructureId: 1867,
    StructureCode: "0651039",
    StructureNom: "CIE D ARC DE FISMES",
    StructureNomCourt: "FISMES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.arc-fismes.com/accueil.php"
  },
  {
    StructureId: 1853,
    StructureCode: "0651041",
    StructureNom: "ARCHERIE DES GOTHS",
    StructureNomCourt: "GUEUX",
    StructureCodeDepartement: "51000",
    AdresseWeb: ""
  },
  {
    StructureId: 1869,
    StructureCode: "0651052",
    StructureNom: "LES ARCHERS DE MAREUIL SUR AY",
    StructureNomCourt: "MAREUIL SUR AY",
    StructureCodeDepartement: "51000",
    AdresseWeb: "https://www.facebook.com/archers.mareuil.5"
  },
  {
    StructureId: 1860,
    StructureCode: "0651040",
    StructureNom: "CLUB SP.GAR.MOURMELON",
    StructureNomCourt: "MOURMELON LE GRAND",
    StructureCodeDepartement: "51000",
    AdresseWeb: null
  },
  {
    StructureId: 2584,
    StructureCode: "0651209",
    StructureNom: "ARC CLUB PARGNYSIEN",
    StructureNomCourt: "PARGNY SUR SAULX",
    StructureCodeDepartement: "51000",
    AdresseWeb: ""
  },
  {
    StructureId: 1848,
    StructureCode: "0651031",
    StructureNom: "CIE D'ARC DE REIMS",
    StructureNomCourt: "REIMS",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.compagnie-arc-reims.com/index.html"
  },
  {
    StructureId: 1852,
    StructureCode: "0651034",
    StructureNom: "COMPAGNIE D'ARC DES VALLONS D'ARGONNE",
    StructureNomCourt: "STE MENEHOULD",
    StructureCodeDepartement: "51000",
    AdresseWeb: ""
  },
  {
    StructureId: 1858,
    StructureCode: "0651050",
    StructureNom: "LES ARCHERS DE LA SUIPPE",
    StructureNomCourt: "SUIPPES",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://les-archers-de-la-suippe.e-monsite.com"
  },
  {
    StructureId: 1854,
    StructureCode: "0651048",
    StructureNom: "STR",
    StructureNomCourt: "TINQUEUX",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://www.tirdereims.com"
  },
  {
    StructureId: 1849,
    StructureCode: "0651038",
    StructureNom: "CIE DES ARCHERS VERTUSIENS",
    StructureNomCourt: "VERTUS",
    StructureCodeDepartement: "51000",
    AdresseWeb: "http://club.sportsregions.fr/archers-vertusiens/"
  },
  {
    StructureId: 1861,
    StructureCode: "0651047",
    StructureNom: "ARCHERY DES LOUPS",
    StructureNomCourt: "VERZY",
    StructureCodeDepartement: "51000",
    AdresseWeb: ""
  },
  {
    StructureId: 1882,
    StructureCode: "0652061",
    StructureNom: "S.L.O. TIR A L'ARC",
    StructureNomCourt: "BETTANCOURT LA FERREE",
    StructureCodeDepartement: "52000",
    AdresseWeb: ""
  },
  {
    StructureId: 1875,
    StructureCode: "0652060",
    StructureNom: "CLUB DES CASTORS BLEUS",
    StructureNomCourt: "CASTORS BLEUS",
    StructureCodeDepartement: "52000",
    AdresseWeb: null
  },
  {
    StructureId: 1874,
    StructureCode: "0652058",
    StructureNom: "1 ERE COMPAGNIE DE CHAUMONT",
    StructureNomCourt: "CHAUMONT",
    StructureCodeDepartement: "52000",
    AdresseWeb: "http://www.archers-de-chaumont.com"
  },
  {
    StructureId: 1873,
    StructureCode: "0652055",
    StructureNom: "1ERE COMPAGNIE D'ARC ECLARON",
    StructureNomCourt: "ECLARON",
    StructureCodeDepartement: "52000",
    AdresseWeb: "http://arc-eclaron.fr"
  },
  {
    StructureId: 1881,
    StructureCode: "0652062",
    StructureNom: "LA FLECHE BRAGARDE",
    StructureNomCourt: "SAINT DIZIER",
    StructureCodeDepartement: "52000",
    AdresseWeb: "http://laflechebragarde.e-monsite.com"
  },
  {
    StructureId: 1078,
    StructureCode: "0654088",
    StructureNom: "LA CHAMPIGNEULLAISE",
    StructureNomCourt: "CHAMPIGNEULLES",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.champigneullaise.fr"
  },
  {
    StructureId: 1059,
    StructureCode: "0654064",
    StructureNom: "CIE DE TIR A L'ARC",
    StructureNomCourt: "CONFLANS EN JARNISY",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://ctacj.free.fr"
  },
  {
    StructureId: 1052,
    StructureCode: "0654081",
    StructureNom: "CIE TIR A L'ARC DE DOMBASLE",
    StructureNomCourt: "DOMBASLE SUR MEURTHE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.arc-dombasle.com/"
  },
  {
    StructureId: 1061,
    StructureCode: "0654086",
    StructureNom: "LES ARCHERS DU SANON",
    StructureNomCourt: "EINVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://sites.google.com/site/lesarchersdusanon/"
  },
  {
    StructureId: 1064,
    StructureCode: "0654082",
    StructureNom: "ASCE SECTION TIR A L'ARC",
    StructureNomCourt: "ERROUVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: ""
  },
  {
    StructureId: 1077,
    StructureCode: "0654066",
    StructureNom: "O.F.P. SECTION TIR A L'ARC",
    StructureNomCourt: "FROUARD",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.ofptiralarc.fr/topic/index.html"
  },
  {
    StructureId: 1054,
    StructureCode: "0654070",
    StructureNom: "ARCHER CLUB HAUCOURT-MOULAINE",
    StructureNomCourt: "HAUCOURT-MOULAINE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://archers-longwy.fr/wordpress"
  },
  {
    StructureId: 2633,
    StructureCode: "0654212",
    StructureNom: "LES ARCHERS SANS LIMITE",
    StructureNomCourt: "HEILLECOURT",
    StructureCodeDepartement: "54000",
    AdresseWeb: ""
  },
  {
    StructureId: 1073,
    StructureCode: "0654080",
    StructureNom: "LA FLECHE HOMECOURTOISE",
    StructureNomCourt: "HOMECOURT",
    StructureCodeDepartement: "54000",
    AdresseWeb: ""
  },
  {
    StructureId: 1063,
    StructureCode: "0654078",
    StructureNom: "LA FLECHE D'AFFRIQUE LUDREENNE",
    StructureNomCourt: "LUDRES",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.laflechedaffrique.fr"
  },
  {
    StructureId: 1055,
    StructureCode: "0654077",
    StructureNom: "LES ARCHERS LUNEVILLOIS",
    StructureNomCourt: "LUNEVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://lesarcherslunevillois.fr"
  },
  {
    StructureId: 2534,
    StructureCode: "0654067",
    StructureNom: "LES FLECHES ROYALES",
    StructureNomCourt: "MONT L'ETROIT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://flechesroyales.wixsite.com/website"
  },
  {
    StructureId: 1051,
    StructureCode: "0654073",
    StructureNom: "1ERE CIE DE TIR A L'ARC DE NANCY",
    StructureNomCourt: "NANCY",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.tiralarcnancy.fr/"
  },
  {
    StructureId: 1067,
    StructureCode: "0654091",
    StructureNom: "COMPAGNIE DE NEUVES MAISONS",
    StructureNomCourt: "NEUVES MAISONS",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://archersneodomiens.unblog.fr"
  },
  {
    StructureId: 1057,
    StructureCode: "0654089",
    StructureNom: "CLUB ARCHERS MUSSIPONTAINS",
    StructureNomCourt: "PONT A MOUSSON",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.campam54.com/"
  },
  {
    StructureId: 1053,
    StructureCode: "0654090",
    StructureNom: "PONT SAINT VINCENT",
    StructureNomCourt: "PONT ST VINCENT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://www.ciearcpontstvincent.fr/"
  },
  {
    StructureId: 1058,
    StructureCode: "0654083",
    StructureNom: "CIE D'ARC DE SEICHAMPS",
    StructureNomCourt: "SEICHAMPS",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://arcseichamps.sportsregions.fr"
  },
  {
    StructureId: 1066,
    StructureCode: "0654071",
    StructureNom: "1ERE COMPAGNIE DES ARCHERS",
    StructureNomCourt: "ST NICOLAS DE PORT",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://archers-portois.over-blog.com/"
  },
  {
    StructureId: 1060,
    StructureCode: "0654079",
    StructureNom: "1ERE CIE D'ARC DU TOULOIS",
    StructureNomCourt: "TOUL",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.arctoulois.fr/"
  },
  {
    StructureId: 1056,
    StructureCode: "0654084",
    StructureNom: "1 CIE VANDOEUVRE",
    StructureNomCourt: "VANDOEUVRE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "https://www.archersvandoeuvre.fr/"
  },
  {
    StructureId: 1069,
    StructureCode: "0654069",
    StructureNom: "CLUB DES ARCHERS DE VARANGEVILLE",
    StructureNomCourt: "VARANGEVILLE",
    StructureCodeDepartement: "54000",
    AdresseWeb: "http://sites.google.com/site/archersdevarangeville/"
  },
  {
    StructureId: 1087,
    StructureCode: "0655093",
    StructureNom: "LES FLECHES BARISIENNES",
    StructureNomCourt: "BAR LE DUC",
    StructureCodeDepartement: "55000",
    AdresseWeb: null
  },
  {
    StructureId: 1090,
    StructureCode: "0655103",
    StructureNom: "ARC CLUB BOULIGNY",
    StructureNomCourt: "BOULIGNY",
    StructureCodeDepartement: "55000",
    AdresseWeb: ""
  },
  {
    StructureId: 1089,
    StructureCode: "0655098",
    StructureNom: "CIE D'ARC DES SEIGNEURS DE BREUX",
    StructureNomCourt: "BREUX",
    StructureCodeDepartement: "55000",
    AdresseWeb: "http://www.arcbreux.fr"
  },
  {
    StructureId: 1086,
    StructureCode: "0655101",
    StructureNom: "LES ARCHERS COMMERCIENS",
    StructureNomCourt: "COMMERCY",
    StructureCodeDepartement: "55000",
    AdresseWeb: "https://archerscommerciens.e-monsite.com/"
  },
  {
    StructureId: 1079,
    StructureCode: "0655102",
    StructureNom: "L.S.G TIR A L'ARC",
    StructureNomCourt: "LIGNY EN BARROIS",
    StructureCodeDepartement: "55000",
    AdresseWeb: ""
  },
  {
    StructureId: 1081,
    StructureCode: "0655096",
    StructureNom: "LES FRANCS ARCHERS DU VERDUNOIS",
    StructureNomCourt: "VERDUN",
    StructureCodeDepartement: "55000",
    AdresseWeb: "http://francsarchersverdunois.fr"
  },
  {
    StructureId: 1085,
    StructureCode: "0655097",
    StructureNom: "LES ARCHERS DES COTES DE MEUSE",
    StructureNomCourt: "VIGNEULLES LES HATTONCHATEL",
    StructureCodeDepartement: "55000",
    AdresseWeb: ""
  },
  {
    StructureId: 1088,
    StructureCode: "0655100",
    StructureNom: "L'ARC VIDUSIEN",
    StructureNomCourt: "VOID VACON",
    StructureCodeDepartement: "55000",
    AdresseWeb: "http://l.arc.vidusien.free.fr/"
  },
  {
    StructureId: 2536,
    StructureCode: "0657121",
    StructureNom: "MAISON DES JEUNES ET DE LA CULTURE D'AMANVILLERS",
    StructureNomCourt: "AMANVILLERS",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1115,
    StructureCode: "0657130",
    StructureNom: "ARS SUR MOSELLE OLYMPIQUE",
    StructureNomCourt: "ARS SUR MOSELLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.amotiralarc.fr/"
  },
  {
    StructureId: 1112,
    StructureCode: "0657136",
    StructureNom: "JSA 1ERE CIE D'ARC",
    StructureNomCourt: "AUDUN LE TICHE",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1107,
    StructureCode: "0657114",
    StructureNom: "LA FLECHE BITCHOISE",
    StructureNomCourt: "BITCHE",
    StructureCodeDepartement: "57000",
    AdresseWeb: null
  },
  {
    StructureId: 1102,
    StructureCode: "0657123",
    StructureNom: "CIE ARCHERS BOUZONVILLE",
    StructureNomCourt: "BOUZONVILLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://archers-bouzonville.club.sportsregions.fr"
  },
  {
    StructureId: 1091,
    StructureCode: "0657137",
    StructureNom: "LES ARCHERS DE CREUTZWALD",
    StructureNomCourt: "CREUTZWALD",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.archers-creutzwald.fr"
  },
  {
    StructureId: 1101,
    StructureCode: "0657111",
    StructureNom: "SOCIETE DE TIR",
    StructureNomCourt: "DIEUZE",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1109,
    StructureCode: "0657109",
    StructureNom: "ARCHER CLUB FAMECKOIS",
    StructureNomCourt: "FAMECK",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1092,
    StructureCode: "0657119",
    StructureNom: "FAREBERSVILLER",
    StructureNomCourt: "FAREBERSVILLER",
    StructureCodeDepartement: "57000",
    AdresseWeb: null
  },
  {
    StructureId: 1124,
    StructureCode: "0657124",
    StructureNom: "L'ARC FAULQUINOIS",
    StructureNomCourt: "FAULQUEMONT",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://arc-faulquinois.fr"
  },
  {
    StructureId: 1105,
    StructureCode: "0657132",
    StructureNom: "CIE ARC FOLSCHVILLER",
    StructureNomCourt: "FOLSCHVILLER",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.archers-folschviller.free-h.net"
  },
  {
    StructureId: 1099,
    StructureCode: "0657116",
    StructureNom: "ARCHERS GORZIENS",
    StructureNomCourt: "GORZE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.archer-gorzien.com"
  },
  {
    StructureId: 1118,
    StructureCode: "0657106",
    StructureNom: "ENTENTE SPORTIVE HAGONDANGE",
    StructureNomCourt: "HAGONDANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://club.sportsregions.fr/lesarchersdelesh"
  },
  {
    StructureId: 1104,
    StructureCode: "0657110",
    StructureNom: "1ERE COMPAGNIE D'ARC HAYANGE",
    StructureNomCourt: "HAYANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://archershayangeois.jimdo.com/"
  },
  {
    StructureId: 1117,
    StructureCode: "0657125",
    StructureNom: "LES ATHABASCANS",
    StructureNomCourt: "HETTANGE GRANDE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesathabascans.org"
  },
  {
    StructureId: 1100,
    StructureCode: "0657128",
    StructureNom: "1ERE CIE TIR ARC",
    StructureNomCourt: "HOMBOURG HAUT",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1122,
    StructureCode: "0657134",
    StructureNom: "LES ARCHERS DE MARANGE SILVANGE",
    StructureNomCourt: "MARANGE SILVANGE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://archers57535.sportsregions.fr"
  },
  {
    StructureId: 1098,
    StructureCode: "0657105",
    StructureNom: "LES ARCHERS DE MARLY-POURNOY LA CHETIVE",
    StructureNomCourt: "MARLY POURNOY",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.archersmarlypournoy.com"
  },
  {
    StructureId: 1113,
    StructureCode: "0657108",
    StructureNom: "LES ARCHERS DU ST QUENTIN ASC METZ",
    StructureNomCourt: "METZ",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesarchersdustquentin.fr/"
  },
  {
    StructureId: 1095,
    StructureCode: "0657126",
    StructureNom: " MONTIGNY LES METZ",
    StructureNomCourt: "MONTIGNY LES METZ A.C.",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://www.archer-montigny-les-metz.com/"
  },
  {
    StructureId: 2591,
    StructureCode: "0657210",
    StructureNom: "LES ARCHERS DE MOYEUVRE-GRANDE",
    StructureNomCourt: "MOYEUVRE-GRANDE",
    StructureCodeDepartement: "57000",
    AdresseWeb: ""
  },
  {
    StructureId: 1093,
    StructureCode: "0657131",
    StructureNom: "PREMIERE COMPAGNIE D'ARC DE SARREBOURG",
    StructureNomCourt: "SARREBOURG",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://tiralarc.sarrebourg.free.fr"
  },
  {
    StructureId: 1094,
    StructureCode: "0657140",
    StructureNom: "1ERE CIE DE TIR A L'ARC",
    StructureNomCourt: "SARREGUEMINES",
    StructureCodeDepartement: "57000",
    AdresseWeb: "https://compagnie-arc-sarreguemines.com/"
  },
  {
    StructureId: 1125,
    StructureCode: "0657122",
    StructureNom: "CIE DES ARCHERS DE STIRING-WENDEL",
    StructureNomCourt: "STIRING-WENDEL",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.lesrobinsstiringeois.fr"
  },
  {
    StructureId: 1096,
    StructureCode: "0657135",
    StructureNom: "1 CTA DE THIONVILLE",
    StructureNomCourt: "THIONVILLE",
    StructureCodeDepartement: "57000",
    AdresseWeb: "http://www.1erectathionville.com"
  },
  {
    StructureId: 2088,
    StructureCode: "0667158",
    StructureNom: "FOYER CLUB JEUNES DU ZORNTHAL",
    StructureNomCourt: "BRUMATH",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://fcj.brumath.free.fr/"
  },
  {
    StructureId: 2092,
    StructureCode: "0667161",
    StructureNom: "LES ARCHERS DE ZORN VALLEE",
    StructureNomCourt: "DETTWILLER",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://acsstjacques.free.fr"
  },
  {
    StructureId: 2083,
    StructureCode: "0667150",
    StructureNom: "A.S.O.R. ERSTEIN",
    StructureNomCourt: "ERSTEIN",
    StructureCodeDepartement: "67000",
    AdresseWeb: ""
  },
  {
    StructureId: 2075,
    StructureCode: "0667144",
    StructureNom: "ARCHERS DE HAGUENAU",
    StructureNomCourt: "HAGUENAU",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.archers-haguenau.fr"
  },
  {
    StructureId: 2094,
    StructureCode: "0667152",
    StructureNom: "CLUB SPORTIF ET ARTISTIQUE ESTIENNE",
    StructureNomCourt: "HAGUENAU ESTIENNE CSA",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://csa-estienne-haguenau-tiralarc.net"
  },
  {
    StructureId: 2076,
    StructureCode: "0667151",
    StructureNom: "CIE D'ARCHERS D'HERRLISHEIM",
    StructureNomCourt: "HERRLISHEIM",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://archersherrlisheim.free.fr/"
  },
  {
    StructureId: 2080,
    StructureCode: "0667154",
    StructureNom: "ASSOCIATION DE TIR A L'ARC ET LOISIRS",
    StructureNomCourt: "ILLKIRCH GRAFFENSTADEN",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.arc-comp-loisir.net"
  },
  {
    StructureId: 2082,
    StructureCode: "0667143",
    StructureNom: "COMPAGNONS D'ARC LINGOLSHEIM",
    StructureNomCourt: "LINGOLSHEIM",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.archers-lingolsheim.fr"
  },
  {
    StructureId: 2077,
    StructureCode: "0667157",
    StructureNom: "CIE D'ARCHERS DU PAYS DE HANAU",
    StructureNomCourt: "NEUWILLER LES SAVERNE",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.archersdupaysdehanau.fr/"
  },
  {
    StructureId: 2079,
    StructureCode: "0667147",
    StructureNom: "LES ARCHERS DE LA HAUTE EHN",
    StructureNomCourt: "OBERNAI",
    StructureCodeDepartement: "67000",
    AdresseWeb: "https://www.ahe-obernai.fr"
  },
  {
    StructureId: 2081,
    StructureCode: "0667159",
    StructureNom: "LES ARCHERS DE SELESTAT",
    StructureNomCourt: "SELESTAT",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://sites.google.com/site/archersselestat/"
  },
  {
    StructureId: 2074,
    StructureCode: "0667160",
    StructureNom: "1 CIE ARC STRASBOURG",
    StructureNomCourt: "STRASBOURG  1ER CIE",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://www.arc-strasbourg.net"
  },
  {
    StructureId: 2091,
    StructureCode: "0667155",
    StructureNom: "AMICALE DE TIR SAINT HUBERT",
    StructureNomCourt: "WISCHES",
    StructureCodeDepartement: "67000",
    AdresseWeb: ""
  },
  {
    StructureId: 2078,
    StructureCode: "0667163",
    StructureNom: "CIE ARCHERS DE WISSEMBOURG",
    StructureNomCourt: "WISSEMBOURG",
    StructureCodeDepartement: "67000",
    AdresseWeb: "http://archerswissembourg67.skyrock.com"
  },
  {
    StructureId: 2105,
    StructureCode: "0668169",
    StructureNom: "ARCHERS ST GEORGES BRUNSTATT",
    StructureNomCourt: "BRUNSTATT",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.asg-brunstatt.fr"
  },
  {
    StructureId: 2099,
    StructureCode: "0668179",
    StructureNom: "COMPAGNONS DU HOHLANDSBOURG",
    StructureNomCourt: "COLMAR",
    StructureCodeDepartement: "68000",
    AdresseWeb: "https://les-compagnons-du-hohlandsbourg.assoconnect.com/"
  },
  {
    StructureId: 2121,
    StructureCode: "0668184",
    StructureNom: "LES ARCHERS DE FERRETTE",
    StructureNomCourt: "FERRETTE",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://lesarchersdeferrette.free.fr"
  },
  {
    StructureId: 2664,
    StructureCode: "0668214",
    StructureNom: "LES ROBIN DE LA DOLLER",
    StructureNomCourt: "KIRCHBERG",
    StructureCodeDepartement: "68000",
    AdresseWeb: ""
  },
  {
    StructureId: 2112,
    StructureCode: "0668168",
    StructureNom: "LES ARCHERS DU FRENZ",
    StructureNomCourt: "KRUTH",
    StructureCodeDepartement: "68000",
    AdresseWeb: ""
  },
  {
    StructureId: 2116,
    StructureCode: "0668172",
    StructureNom: "LES ARCHERS DU LION ACSP",
    StructureNomCourt: "MULHOUSE ARCHERS DU LION",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.acspm.fr/accueil.html"
  },
  {
    StructureId: 2100,
    StructureCode: "0668187",
    StructureNom: "MULHOUSE C.A.B.",
    StructureNomCourt: "MULHOUSE CAB",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.cabmulhouse.com"
  },
  {
    StructureId: 2096,
    StructureCode: "0668183",
    StructureNom: "SOCIETE DE TIR A L ARC MULHOUSE",
    StructureNomCourt: "MULHOUSE STA",
    StructureCodeDepartement: "68000",
    AdresseWeb: "https://stam-mulhouse.fr"
  },
  {
    StructureId: 2113,
    StructureCode: "0668176",
    StructureNom: "LES ARCHERS DE LA VALLEE DE MUNSTER",
    StructureNomCourt: "MUNSTER",
    StructureCodeDepartement: "68000",
    AdresseWeb: ""
  },
  {
    StructureId: 2102,
    StructureCode: "0668174",
    StructureNom: "RIBEAUVILLE (RIBEAUPIERRE)",
    StructureNomCourt: "RIBEAUVILLE",
    StructureCodeDepartement: "68000",
    AdresseWeb: ""
  },
  {
    StructureId: 2119,
    StructureCode: "0668167",
    StructureNom: "ARCHERS DU CERCLE RIXHEIM",
    StructureNomCourt: "RIXHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.archers-rixheim.fr"
  },
  {
    StructureId: 2120,
    StructureCode: "0668175",
    StructureNom: "LES ARCHERS DU FLORIVAL",
    StructureNomCourt: "SOULTZ",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.mjc-buhl.com"
  },
  {
    StructureId: 2109,
    StructureCode: "0668173",
    StructureNom: "ARC CLUB STE CX PLAINE",
    StructureNomCourt: "STE CROIX ARC CLUB",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://arc-club-ste-croix.asso-web.com"
  },
  {
    StructureId: 2097,
    StructureCode: "0668191",
    StructureNom: "COMPAGNIE D'ARC STE CROIX EN PLAINE",
    StructureNomCourt: "STE CROIX EN PLAINE CIE",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.compagniedarc.fr"
  },
  {
    StructureId: 2098,
    StructureCode: "0668171",
    StructureNom: "LES ARCHERS DE LA THUR",
    StructureNomCourt: "THANN",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.archersdelathur.org"
  },
  {
    StructureId: 2606,
    StructureCode: "0668211",
    StructureNom: "ARCHERS DE LA ROSERAIE",
    StructureNomCourt: "THANN",
    StructureCodeDepartement: "68000",
    AdresseWeb: ""
  },
  {
    StructureId: 2104,
    StructureCode: "0668190",
    StructureNom: "LES ARCHERS DE VOLGELSHEIM",
    StructureNomCourt: "VOLGELSHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "",
  },
  {
    StructureId: 2117,
    StructureCode: "0668180",
    StructureNom: "CIE D'ISHI ARCHERS DE WITTELSHEIM",
    StructureNomCourt: "WITTELSHEIM",
    StructureCodeDepartement: "68000",
    AdresseWeb: "http://www.compagnie-ishi.com"
  },
  {
    StructureId: 1128,
    StructureCode: "0688196",
    StructureNom: "CERCLE DES ARCHERS",
    StructureNomCourt: "CHARMES",
    StructureCodeDepartement: "88000",
    AdresseWeb: ""
  },
  {
    StructureId: 1126,
    StructureCode: "0688204",
    StructureNom: "1ERE CIE D'ARCHERS D'EPINAL",
    StructureNomCourt: "EPINAL 1ER",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://arc-epinal.fr"
  },
  {
    StructureId: 1137,
    StructureCode: "0688205",
    StructureNom: "CERCLE DES ARTS MARTIAUX",
    StructureNomCourt: "LE VAL D'AJOL",
    StructureCodeDepartement: "88000",
    AdresseWeb: ""
  },
  {
    StructureId: 1136,
    StructureCode: "0688200",
    StructureNom: "LES ARCHERS MIRECURTIENS",
    StructureNomCourt: "MIRECOURT",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://www.arcmirecourt.fr"
  },
  {
    StructureId: 1134,
    StructureCode: "0688208",
    StructureNom: "COMPAGNIE DES ARCHERS DE NEUFCHATEAU",
    StructureNomCourt: "NEUFCHATEAU",
    StructureCodeDepartement: "88000",
    AdresseWeb: ""
  },
  {
    StructureId: 1133,
    StructureCode: "0688203",
    StructureNom: "1ERE COMPAGNIE D'ARC DE SAINT-DIE-DES-VOSGES",
    StructureNomCourt: "ST DIE",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://club-arc-saint-die.fr"
  },
  {
    StructureId: 1127,
    StructureCode: "0688192",
    StructureNom: "EST TIR A L'ARC",
    StructureNomCourt: "THAON LES VOSGES",
    StructureCodeDepartement: "88000",
    AdresseWeb: "http://www.esthaon-tiralarc.fr/"
  },
  {
    StructureId: 1129,
    StructureCode: "0688201",
    StructureNom: "1E CIE DES HTES VOSGES",
    StructureNomCourt: "VAGNEY",
    StructureCodeDepartement: "88000",
    AdresseWeb: ""
  },
  {
    StructureId: 1141,
    StructureCode: "0688207",
    StructureNom: "LA FLECHE THERMALE",
    StructureNomCourt: "VITTEL",
    StructureCodeDepartement: "88000",
    AdresseWeb: ""
  },
  {
    StructureId: 1130,
    StructureCode: "0688206",
    StructureNom: "VALLEE DES LACS",
    StructureNomCourt: "XONRUPT LONGEMER",
    StructureCodeDepartement: "88000",
    AdresseWeb: null
  },
]
